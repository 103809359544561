import { CustomField, CustomFieldSettings, ParsedCustomField } from "@/api/interface/custom-field";
import { CustomFieldOption } from "@/api/interface/custom-field";
import { CustomFieldVisibility } from "@/consts/CustomFieldVisibility";
import { Location } from "@/api/interface/location";
import { WaitTime } from "@/api/interface/wait-time";
import dayjs, { Dayjs } from "dayjs";
import { DYNAMIC_CF_TYPES } from "@/consts/CustomFieldTypesEnum";
import { Queue } from "@/api/interface/queue";

export const doesCfVisibilityMatch = (isSci: boolean, visibility: string) => {
  if (visibility === CustomFieldVisibility.all.value) {
    return true
  }
  if (isSci && visibility === CustomFieldVisibility.self_check_in.value) {
    return true
  }
  if (!isSci && visibility === CustomFieldVisibility.webapp.value) {
    return true
  }
  return false
}

export const isCfPossibleForLocation = (
  cf: CustomField,
  isUsingSharedSettings: boolean,
  hasOnlyOneLocation: boolean,
  locationId: Location['id'],
  isSci: boolean,
) => {
  if (
    (isUsingSharedSettings || hasOnlyOneLocation)
    && doesCfVisibilityMatch(isSci, cf.visibility)
    && cf.cfableType === 'business'
  ) {
    return true
  }
  if (
    !(isUsingSharedSettings || hasOnlyOneLocation)
    && doesCfVisibilityMatch(isSci, cf.visibility)
    && (cf.cfableId === locationId || cf.cfableType === 'business')
  ) {
    return true
  }
  return false
}

export const isUsingCustomQueueSelector = (cfs: CustomField[]) => {
  return cfs?.filter(cf => cf.type === 'queueselect').length > 0
}

export const mapQueueOptionValueToValueMeta = (value: string, options: CustomFieldOption[]) => {
  const option = options.find(item => item.value === value)
  return option.valueMeta
}

export const mapOptions = (options: CustomField["options"], waitTimes: WaitTime[]) => {
  const waitTimeQueues = waitTimes.map(x => x.queueId)

  return options?.map((x) => ({
    ...x,
    label: x.value,
    value: x.value,
    data: { 
      ...x.data, 
      icon: x.icon, 
      valueMeta: x.valueMeta, 
      queueName: waitTimes?.find(wt => wt.queueId === x.valueMeta)?.queueName
    },
  }))
    .filter(x => {
      return waitTimeQueues.includes(x.data.valueMeta)
  })
}

export const convertApiTimeToFormTime = (value?: string): Dayjs => {
  if (value) {
    return dayjs(value)
  }
  return dayjs()
}

export const getQueuesWithDynamicField = (
  customFields: CustomField[],
  queues: Array<string>
) => {
  // is there already a dynamic field for a queue applied to the current field
  return queues.reduce((acc, d) => {
    const dynamicCf = customFields.find((cf) => {
      const parsedSettings = parseCfSettings(cf.settings)
      return cf.enabledAt.includes(d) && typeof parsedSettings === 'object' && parsedSettings?.isDynamic
    })
    if (dynamicCf) {
      acc[d] = {
        dynamicCfId: dynamicCf.id
      }
    }
    return acc
  }, {})
}

export const canBeDynamicField = (
  queuesWithDynamicField: any,
  customField: Partial<ParsedCustomField>
) => {
  const { id: cfId, type, enabledAt, visibility } = customField
  const queueIds = Object.keys(queuesWithDynamicField)
  const isAllowedType = DYNAMIC_CF_TYPES.includes(type)
  const isAllowedVisibility = visibility !== 'self_check_in'

  // if there are no queue IDs cannot be dynamic
  // because queues are necessary to determine possibility
  if (customField.enabledAt.length === 0) {
    return false
  }

  // queue does not already have a dynamic field other than the current field
  const enabledAtQueuesWithDynamicField = enabledAt?.filter((qId) => {
    return queueIds?.includes(qId) &&
      queuesWithDynamicField[qId]?.dynamicCfId !== cfId
  })
  
  // if enabled queues don't already have a dynamic field and type and visibility are allowed
  return enabledAtQueuesWithDynamicField?.length < 1 &&
    isAllowedType &&
    isAllowedVisibility
}

export const parseCfSettings = (settings: any): CustomFieldSettings => {
  if (Array.isArray(settings)) {
    return {}
  }
  if (typeof settings === 'string') {
    return JSON.parse(settings)
  }
  return settings
}