import { useCookies } from "react-cookie"
import { useEffect, useMemo } from "react"
import { setIsUsingSharedSettings } from "@/store/slices/pages/manageSlice"

/**
 * Get cookies set by the mobile app when displaying web pages in a react-native webview component.
 * Cookies:
 * nextme_mobile_location: locationId or string 'shared_settings' to indicate the state of the mobile app location picker.
 */

export default function useMobileCookies() {
  const [cookies] = useCookies(['nextme_mobile_location'])

  const locationIdCookie = useMemo(() => {
      const locationIdCookieVal = cookies?.['nextme_mobile_location']
      if (locationIdCookieVal) {
        return locationIdCookieVal
      }
    }, [cookies])

  const isUsingMobileSharedSettings = useMemo(() => {
    return locationIdCookie && locationIdCookie === 'shared_settings'
  }, [locationIdCookie])

    useEffect(() => { 
      if (locationIdCookie && locationIdCookie === 'shared_settings') {
        setIsUsingSharedSettings(true)
      } else if (locationIdCookie && locationIdCookie !== 'shared_settings') {
        setIsUsingSharedSettings(false)
      }
    }, [locationIdCookie])

    return { 
      isUsingMobileSharedSettings, 
      locationIdCookieVal: locationIdCookie 
    }
}